import React, { useEffect } from "react";
import Layout from "../components/Layout";
import AppRoutes from "../routes/appRoutes";
import styled from "styled-components";
const RootContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  background: rgb(209, 225, 232);
`;
export default function Main() {
  return (
    <>
      <Layout>
        <RootContainer>
          <AppRoutes />
        </RootContainer>
      </Layout>
    </>
  );
}

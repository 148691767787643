import React from "react";
import InspectionContext from "../context/inspection";
import { getInspection } from "../api/api";

export default function InspectionProvider({ children }) {
  const [propertyName, setPropertyName] = React.useState(null);
  const [editableProperties, setEditableProperties] = React.useState(null);
  const [currentSelectedInspection, setCurrentSelectedInspection] =
    React.useState(null);
  const [unUsedIndex, setUnsedIndex] = React.useState(null);
  const [unUsedCatIndex, setUnsedCatIndex] = React.useState(null);

  const [listData, setListData] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const changeIndex = async () => {
    const inspectionData = await getInspection();

    setSelectedIndex(inspectionData?.inspections?.[0]?.items.length - 1);
  };
  const setFirstEditableProperty = async () => {
    const inspectionData = await getInspection();

    setEditableProperties(
      inspectionData?.inspections[
        inspectionData?.inspections?.length > 1 ? 1 : 0
      ]?.items[0]
    );
    setPropertyName(
      inspectionData?.inspections?.length > 0 ? "inspection" : ""
    );
  };
  return (
    <>
      <InspectionContext.Provider
        value={{
          propertyName,
          editableProperties,
          setPropertyName,
          setEditableProperties,
          currentSelectedInspection,
          setCurrentSelectedInspection,
          selectedIndex,
          setSelectedIndex,
          listData,
          setListData,
          changeIndex,
          setFirstEditableProperty,
          unUsedIndex: unUsedIndex,
          setUnsedIndex,
          unUsedCatIndex: unUsedCatIndex,
          setUnsedCatIndex,
        }}
      >
        {children}
      </InspectionContext.Provider>
    </>
  );
}

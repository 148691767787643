import React from "react";
import Navbar from "./navbar";
export default function Index({ children }) {
  return (
    <>
      <Navbar/>
      {children}
    </>
  );
}

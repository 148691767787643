import { login as loginEmailPassword } from "../api/api";
import { useContext } from "react";
import AuthContext from "../context/Auth";
import jwt_decode from "jwt-decode";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const TOKEN_KEY = "access_token";

export default function useAuthHook() {
  let { user, setUser, isAuthenticated, setIsAuthenticated } =
    useContext(AuthContext);

  const login = async (loginData) => {
    try {
      let data = await loginEmailPassword(loginData);

      //console.log("login user data: " + JSON.stringify(data))

      // this all needs to be reworked for multiple groups, roles, portals, this is just to log into admin portal for now
      for (let group of data?.groups){
        console.log("user part of group: " + group)

        if (group?.shopName == "CheckDrv" || group?.shopName == "Garage") {
          //console.log("group name found! authenticating!")
          setIsAuthenticated(true);

          if (data.accessToken) {
            localStorage.setItem(TOKEN_KEY, data.accessToken);
    
            setUser(jwt_decode(data.accessToken));
    
            setIsAuthenticated(true);
          }

          return data;
        }
      }

      toast.error("User could not be authenticated")
      setIsAuthenticated(false);
      return null
    } catch (e) {
      throw e;
    }
  };

  const logout = async () => {
    localStorage.removeItem(TOKEN_KEY);
    setIsAuthenticated(false);
  };

  //functions we're exposing
  return {
    login,
    logout,
    isAuthenticated,
    user,
  };
}

import React from "react";
import PromptContext from "../context/prompt";
import { getChatGptPrompts } from "../api/api";

export default function PromptProvider({ children }) {
  const [propertyName, setPropertyName] = React.useState(null);
  const [editableProperties, setEditableProperties] = React.useState(null);
  const [currentSelectedPrompt, setCurrentSelectedPrompt] =
    React.useState(null);
  const [unUsedIndex, setUnsedIndex] = React.useState(null);
  const [unUsedCatIndex, setUnsedCatIndex] = React.useState(null);

  const [listData, setListData] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const changeIndex = async () => {
    const promptData = await getChatGptPrompts();
    setSelectedIndex(promptData?.length - 1);
  };
  const setFirstEditableProperty = async () => {
    const promptData = await getChatGptPrompts();

    setEditableProperties(
      promptData?.[0]
    );
    
    setPropertyName(
      promptData?.length > 0 ? "prompt" : ""
    );
  };
  return (
    <>
      <PromptContext.Provider
        value={{
          propertyName,
          editableProperties,
          setPropertyName,
          setEditableProperties,
          currentSelectedPrompt,
          setCurrentSelectedPrompt,
          selectedIndex,
          setSelectedIndex,
          listData,
          setListData,
          changeIndex,
          setFirstEditableProperty,
          unUsedIndex: unUsedIndex,
          setUnsedIndex,
          unUsedCatIndex: unUsedCatIndex,
          setUnsedCatIndex,
        }}
      >
        {children}
      </PromptContext.Provider>
    </>
  );
}

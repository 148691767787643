import React from "react";
import UserContext from "../context/users";
import { getShops } from "../api/api";

export default function UsersProvider({ children }) {
  const [propertyName, setPropertyName] = React.useState(null);
  const [editableProperties, setEditableProperties] = React.useState(null);
  const [currentSelectedShop, setCurrentSelectedShop] =
    React.useState(null);
  const [unUsedIndex, setUnsedIndex] = React.useState(null);
  const [unUsedCatIndex, setUnsedCatIndex] = React.useState(null);

  const [listData, setListData] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const changeIndex = async () => {
    const shopsData = await getShops();
    setSelectedIndex(shopsData?.[0]?.groups.length - 1);
  };
  const setFirstEditableProperty = async () => {
    const shopsData = await getShops();

    setEditableProperties(
      //shopsData?.[
      //  shopsData?.length > 1 ? 1 : 0
      //]?.items[0]
    );
    setPropertyName(
      shopsData?.length > 0 ? "shop" : ""
    );
  };
  return (
    <>
      <UserContext.Provider
        value={{
          propertyName,
          editableProperties,
          setPropertyName,
          setEditableProperties,
          currentSelectedShop,
          setCurrentSelectedShop,
          selectedIndex,
          setSelectedIndex,
          listData,
          setListData,
          changeIndex,
          setFirstEditableProperty,
          unUsedIndex: unUsedIndex,
          setUnsedIndex,
          unUsedCatIndex: unUsedCatIndex,
          setUnsedCatIndex,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
}

import React from "react";
import { Route, Navigate, Routes, Redirect } from "react-router-dom";
import AuthRoutes from "./authRoutes";
import Main from "../screens/main";
import useAuth from "../Hooks/useAuthHook";
import AddAdds from "../screens/PrivacyPolicy/app-ads";
const Login = React.lazy(() => import("../screens/Auth/login"));
const Inspection = React.lazy(() => import("../screens/Inspections/index"));
const ChatGptPrompts = React.lazy(() => import("../screens/ChatGptPrompts/index"));
const Users = React.lazy(() => import("../screens/Users/index"));
const AddInspection = React.lazy(() => import("../screens/Inspections/add"));
const ShowReport = React.lazy(() => import("../screens/reports/index"));
const PrivacyPolicy = React.lazy(() =>
  import("../screens/PrivacyPolicy/index")
);

export default function Index() {
  const { isAuthenticated } = useAuth();
  return (
    <>
      <React.Suspense>
        {isAuthenticated ? (
          <>
            <Routes>
              <Route path="" element={<Main />}>
                <Route path="/users" element={<Users />} />
                <Route path="/inspection" element={<Inspection />} />
                <Route path="/ChatGptPrompts" element={<ChatGptPrompts />} />
                <Route path="" element={<Navigate to="/inspection" replace />} />
                <Route path="*" element={<Navigate to="/inspection" replace />} />
              </Route>
              <Route path="" element={<Navigate to="/inspection" replace />} />
              <Route path="*" element={<Navigate to="/inspection" replace />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/auth" element={<AuthRoutes />}>
                <Route path="login" element={<Login />} />
              </Route>
              <Route
                path="/deleteUserAccountInstructions"
                element={<PrivacyPolicy />}
              />
              <Route
                exact
                path="/showUserReport/:inspectionId"
                element={<ShowReport />}
              />
              <Route path="/app-ads.txt" element={<AddAdds />} />

              <Route path="*" element={<Navigate to="/auth/login" />} />
            </Routes>
          </>
        )}
      </React.Suspense>
    </>
  );
}

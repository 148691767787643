import React from "react";
import { Route, Routes } from "react-router-dom";
import {  Outlet } from "react-router-dom";

export default function AppRoutes() {
  return (
    <>
      <Outlet/>
    </>
  );
}

import instance from "./axiosConfig";
export async function login(_data) {
  let { data } = await instance.post("/auth/login", _data);
  return data;
}
export async function logout(_data) {
  let { data } = await instance.post("/auth/logout", _data);
  return data;
}
export async function createInspection(_data) {
  let { data } = await instance.post("/inspection/createInspection", _data);
  return data;
}
export async function updateInspection(_data) {
  let { data } = await instance.put(
    `/inspection/updateInspection/${_data.inspectionId}`,
    _data
  );
  return data;
}
export async function archiveRecord(inspectionId) {
  let { data } = await instance.put(
    `/inspection/archiveInspection/${inspectionId}`
  );
  return data;
}
export async function getInspection() {
  let { data } = await instance.get(`/inspection/getInspection?flag=${"true"}`);

  return data;
}
export async function getChatGptPrompts() {
  let { data } = await instance.get(`/ai/getChatGptPrompts?flag=${"true"}`);
  return data;
}
export async function createPrompt(_data) {
  let { data } = await instance.post("/ai/createPrompt", _data);
  return data;
}
export async function updatePrompt(_data) {
  let { data } = await instance.put("/ai/updatePrompt", _data);
  return data;
}
export async function deletePrompt(_data) {
  let { data } = await instance.put("/ai/deletePrompt", _data);
  return data;
}
export async function deletePromptSection(_data) {
  let { data } = await instance.put("/ai/deletePromptSection", _data);
  return data;
}
export async function deletePromptCategory(_data) {
  let { data } = await instance.put("/ai/deletePromptSection", _data);
  return data;
}
export async function deletePromptItem(_data) {
  let { data } = await instance.put("/ai/deletePromptSection", _data);
  return data;
}
export async function createPromptSection(_data) {
  let { data } = await instance.post("/ai/createPromptSection", _data);
  return data;
}
export async function updatePromptSection(_data) {
  let { data } = await instance.put(
    `/ai/updatePromptSection/${_data.sectionId}`,
    _data
  );
  return data;
}
export async function createPromptCategory(_data) {
  let { data } = await instance.post("/ai/createPromptCategory", _data);
  return data;
}
export async function updatePromptCategory(_data) {
  let { data } = await instance.put(
    `/ai/updatePromptCategory/${_data.categoryId}`,
    _data
  );
  return data;
}
export async function createPromptItem(_data) {
  let { data } = await instance.post("/ai/createPromptItem", _data);
  return data;
}
export async function updatePromptItem(_data) {
  let { data } = await instance.put(
    `/ai/updatePromptItem/${_data.itemId}`,
    _data
  );
  return data;
}
export async function archivePrompt(promptId) {
  let { data } = await instance.put(`/ai/archivePrompt/${promptId}`);
  return data;
}
export async function archivePromptSection(sectionId) {
  let { data } = await instance.put(`/ai/archivePromptSection/${sectionId}`);
  return data;
}
export async function archivePromptCategory(categoryId) {
  let { data } = await instance.put(`/ai/archivePromptCategory/${categoryId}`);
  return data;
}
export async function archivePromptItem(itemId) {
  let { data } = await instance.put(`/ai/archivePromptItem/${itemId}`);
  return data;
}
export async function createSection(_data) {
  let { data } = await instance.post("/inspection/addSection", _data);
  return data;
}
export async function updateSection(_data) {
  let { data } = await instance.put(
    `/inspection/updateSection/${_data.sectionId}`,
    _data
  );
  return data;
}
export async function archiveSection(sectionId) {
  let { data } = await instance.put(`/inspection/archiveSection/${sectionId}`);
  return data;
}
export async function changePromptOrder(_data) {
  let { data } = await instance.put(`/ai/changeOrder`, _data);
  return data;
}
export async function changeOrder(_data) {
  let { data } = await instance.put(`/inspection/changeOrder`, _data);
  return data;
}
export async function createCategory(_data) {
  let { data } = await instance.post("/inspection/addCategory", _data);
  return data;
}
export async function updateCategory(_data) {
  let { data } = await instance.put(
    `/inspection/updateCategory/${_data.categoryId}`,
    _data
  );
  return data;
}
export async function archiveCategory(categoryId) {
  let { data } = await instance.put(
    `/inspection/archiveCategory/${categoryId}`
  );
  return data;
}
export async function createItem(_data) {
  let { data } = await instance.post("/inspection/addItem", _data);
  return data;
}
export async function updateItem(_data) {
  let { data } = await instance.put(
    `/inspection/updateItem/${_data.itemId}`,
    _data
  );
  return data;
}
export async function archiveItem(itemId) {
  let { data } = await instance.put(`/inspection/archiveItem/${itemId}`);
  return data;
}
export async function deleteSection(sectionId) {
  let { data } = await instance.put(`/inspection/deleteSection/${sectionId}`);
  return data;
}
export async function deleteCategory(categoryId) {
  let { data } = await instance.put(`/inspection/deleteCategory/${categoryId}`);
  return data;
}
export async function deleteItem(itemId) {
  let { data } = await instance.put(`/inspection/deleteItem/${itemId}`);
  return data;
}
export async function createNewType(_data) {
  let { data } = await instance.post("/inspection/newAppliesToType", _data);
  return data;
}
export async function getNewTypes() {
  let { data } = await instance.get("/inspection/getAppliesToTypes");
  return data;
}
export async function unArchiveThisProperty(id, flag) {
  let { data } = await instance.put(
    `/inspection/unArchiveThisProeperty?id=${id}&&flag=${flag}`
  );
  return data;
}
export async function getInspectionReport(inspectionId) {
  let { data } = await instance.get(`/getInspectionReport/${inspectionId}`);

  return data;
}



/*
 USER API FUNCTIONS
*/

export async function getShops() {
  let { data } = await instance.get(`/users/getShops`);

  return data;
}
export async function createShop(_data) {
  let { data } = await instance.post("/users/createShop", _data);
  return data;
}
export async function updateShop(_data) {
  let { data } = await instance.put(
    `/users/updateShop/${_data.shopId}`,
    _data
  );
  return data;
}
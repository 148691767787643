import React from "react";
import {  Outlet } from "react-router-dom";
// const Login = React.lazy(() => import("../screens/Auth/login"));
export default function AuthRoutes() {
  return (
    <>
      <Outlet/>
    </>
  );
}

import React from "react";
import appAdds from "../../screens/PrivacyPolicy/app-ads.txt";

export default function AddAdds() {
  const [showData, setShowData] = React.useState();
  React.useEffect(() => {
    fetch(appAdds)
      .then((r) => r.text())
      .then((text) => {
        // console.log("text decoded:", text);
        setShowData(text);
      });
  }, []);

  return (
    <>
      <div>{showData ? showData : ""}</div>
    </>
  );
}
